.card__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-weight: 300;
  background-color: #20334F;
  width: 200px;
  height: 124px;
  border-radius: 12px;
  padding: 12px 16px;
}

